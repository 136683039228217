'use es6';

import { fetchProfileListing, fetchProfileListingPreview } from '../clients/profilesClient';
import * as ActionTypes from './ActionTypes';
export const getProfileListing = (slug, isPreview) => dispatch => {
  dispatch({
    type: ActionTypes.PROFILE_LISTING_REQUESTED,
    meta: {
      slug
    }
  });
  const request = isPreview ? fetchProfileListingPreview() : fetchProfileListing(slug);
  return request.then(resp => {
    dispatch({
      type: ActionTypes.PROFILE_LISTING_RECEIVED,
      payload: resp,
      meta: {
        slug
      }
    });
    return resp;
  }).catch(error => {
    dispatch({
      type: ActionTypes.PROFILE_LISTING_FAILED,
      payload: error,
      meta: {
        slug
      }
    });
  });
};
export const clearProfileListing = () => dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_PROFILE_LISTING
  });
};