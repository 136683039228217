'use es6';

import { environmentUrl, hubletApi, withUrl } from 'hub-http/middlewares/core';
export const privateHubTeamApi = environmentUrl(hubletApi('private', 'hubteam'));
export const privateApi = environmentUrl(hubletApi('private', 'hubapi'));
export const toolsApi = environmentUrl(hubletApi('tools', 'hubteam'));
export const mapHubapiToTools = options => {
  const match = /^(api|app)(?:-(.*))?.(?:hub(?:spot|api))(qa)?.com$/;
  const hubletRegexp = /([a-z]+[0-9])/;
  return withUrl(url => {
    const matches = url.hostname.match(match);
    if (!matches) {
      return url;
    }

    // there are some subdomains of the form api-{X} (api-auto) that are not hublets
    const hublet = matches[2] && matches[2].match(hubletRegexp) ? `-${matches[2]}` : '';
    const isQa = !!matches[3];
    url.hostname = `tools${hublet}.hubteam${isQa ? 'qa' : ''}.com`;
    return url;
  })(options);
};