import { registerQuery } from 'data-fetching-client';
import { ecosystemPublicClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const GET_REVIEWS_SUMMARY = registerQuery({
  fieldName: 'reviewSummaryResponse',
  args: ['entityId', 'reviewType'],
  fetcher({
    entityId,
    reviewType
  }) {
    return ecosystemPublicClient.get(`reviews/summaries?type=${reviewType}&entityIds=${entityId}`);
  }
});