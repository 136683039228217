'use es6';

import { createStack } from 'hub-http';
import * as core from 'hub-http/middlewares/core';
import * as debug from 'hub-http/middlewares/debug';
import * as hubapi from 'hub-http/middlewares/hubapi';
import * as hubSpotter from '../middlewares/hubSpotter';
import * as loadBalancers from '../middlewares/loadBalancers';
import cookieAuth from '../middlewares/cookieAuth';
export const createHubSpotterStack = lbMiddleware => createStack(core.services, hubSpotter.defaults, core.jsonBody, core.httpsOnly, lbMiddleware,
// this needs to go before cookieAuth since it changes the URL and hence cookie that needs to be used
loadBalancers.mapHubapiToTools, cookieAuth, core.withQuery, debug.rewriteUrl, core.reportOptionsError, hubSpotter.logoutOnUnauthorized, hubapi.retryOnError, core.validateStatus, core.jsonResponse);
export const privateHubTeamStack = createHubSpotterStack(loadBalancers.privateHubTeamApi);
export const privateHubSpotterStack = createHubSpotterStack(loadBalancers.privateApi);
export const toolsHubSpotterStack = createHubSpotterStack(loadBalancers.toolsApi);