import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["answers", "attributes", "content", "employees", "marketplaceIndustry", "original", "ratings", "reply", "reviewerData", "tags"],
  _excluded2 = ["answers", "attributes", "content", "original", "ratings", "reply", "tags"];
import I18n from 'I18n';
import produce from 'immer';
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import Reply from './Reply';
import * as ReviewTypes from '../constants/ReviewType';
import { RatingAxes, AppQualityRatingsOrder } from '../constants/RatingAxes';
import { PREFER_NOT_TO_SAY } from '../constants/CompanySize';
const reviewResponseDefaults = {
  answers: {},
  attributes: [],
  avatarUrl: null,
  companyName: null,
  content: {},
  createdAt: null,
  employees: null,
  id: null,
  lastEditedAt: null,
  marketplaceIndustry: null,
  offeringVersion: null,
  original: null,
  ratings: {},
  reply: {
    id: null,
    repliedAt: null,
    reply: null,
    replierDisplayName: null,
    avatarUrl: null,
    companyName: null
  },
  reviewerData: {},
  reviewerDisplayName: null,
  reviewType: null,
  showAvatar: true,
  tags: {}
};
export default class Review extends createImmutableObjectClass(reviewResponseDefaults, {
  fromJSON: _ref => {
    let {
        answers,
        attributes,
        employees,
        marketplaceIndustry,
        original,
        ratings,
        reply,
        reviewerData,
        tags
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    const reviewerDataEmployees = reviewerData && reviewerData.employees || null;
    const reviewerDataMarketplaceIndustry = reviewerData && reviewerData.industry || null;
    return Object.assign({
      answers: new Map(Object.entries(answers)),
      archived: false,
      archivedReplies: [],
      attributes: attributes.map(attr => new Map(Object.entries(attr))),
      content: new Map(),
      employees: reviewerDataEmployees || employees,
      entityId: null,
      listingName: null,
      marketplaceIndustry: reviewerDataMarketplaceIndustry || marketplaceIndustry,
      offeringType: null,
      original: {
        answers: original ? new Map(Object.entries(original.answers)) : new Map(),
        ratings: original ? new Map(Object.entries(original.ratings)) : new Map(),
        tags: original ? new Map(Object.entries(original.tags)) : new Map()
      },
      portalId: reviewerData.portalId || null,
      providerPortalId: null,
      ratings: new Map(Object.entries(ratings)),
      reply: reply ? new Reply(reply) : new Reply(),
      tags: new Map(Object.entries(tags)),
      userId: null
    }, rest);
  },
  toJSON: _ref2 => {
    let {
        answers,
        attributes,
        ratings,
        reply,
        tags
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      answers: Object.fromEntries(answers),
      attributes: attributes.map(attr => Object.fromEntries(attr)),
      content: {},
      original: null,
      ratings: Object.fromEntries(ratings),
      reply: reply.toJSON(),
      reviewerData: {},
      tags: Object.fromEntries(tags)
    }, rest);
  }
}) {
  static fromArray(data, reviewType) {
    return data.map(review => new Review(Object.assign({}, review, {
      reviewType
    })));
  }
  static toEmployeeString(range) {
    if (!range) {
      return '';
    }
    let rangeKey;
    let formattedMin;
    let formattedMax;
    if (range === PREFER_NOT_TO_SAY) {
      rangeKey = 'undisclosed';
    } else {
      const [__range, min, max] = range.split('_');
      formattedMin = I18n.formatNumber(parseInt(min, 10));
      formattedMax = I18n.formatNumber(parseInt(max, 10));
      rangeKey = min === '1' ? 'single' : max ? 'range' : 'indefinite';
    }
    return I18n.text(`marketplace-ui-reviews.employees.${rangeKey}`, {
      min: formattedMin,
      max: formattedMax
    });
  }
  getHelpfulCount() {
    return this.get('attributes').filter(attr => attr.get('helpful')).length;
  }
  toggleHelpful(reviewId, userId, helpful) {
    return produce(this, draftReview => {
      const indexToUpdate = draftReview.get('attributes').findIndex(attr => attr.get('userId') === userId);
      const newAttributes = draftReview.attributes;
      if (indexToUpdate !== -1) {
        newAttributes[indexToUpdate].set('helpful', helpful);
      } else {
        newAttributes.push(new Map([['reviewId', reviewId], ['userId', userId], ['helpful', helpful]]));
      }
      draftReview.set('attributes', newAttributes);
    });
  }
  getQualityRatings(showOriginal) {
    const initialMap = showOriginal ? this.original.ratings : this.ratings;
    const qualityRatingsMap = produce(initialMap, ratingsDraft => {
      ratingsDraft.delete(RatingAxes.OVERALL);
    });
    switch (this.reviewType) {
      case ReviewTypes.APP:
        return AppQualityRatingsOrder.reduce((final, key) => {
          return final.set(key, initialMap.get(key));
        }, new Map());
      default:
        return qualityRatingsMap;
    }
  }
  setOverallRating(overallRating) {
    return produce(this, reviewDraft => {
      reviewDraft.get('ratings').set(RatingAxes.OVERALL, overallRating);
    });
  }
  setEmployees(employeeSize) {
    return produce(this, reviewDraft => {
      reviewDraft.set('employees', employeeSize);
    });
  }
  setIndustry(industry) {
    return produce(this, reviewDraft => {
      reviewDraft.set('marketplaceIndustry', industry);
    });
  }
  hasContent() {
    return this.answers.size > 0;
  }
  updateReview(updates) {
    return produce(this, reviewDraft => {
      Object.entries(updates).forEach(([key, value]) => {
        if (key === 'answers') {
          value.forEach((updateValue, updateKey) => {
            if (updateValue === '') {
              reviewDraft.get(key).delete(updateKey);
            } else {
              reviewDraft.get(key).set(updateKey, updateValue);
            }
          });
        } else if (key === 'tags') {
          value.forEach((updateValue, updateKey) => {
            reviewDraft.get(key).set(updateKey, updateValue);
          });
        } else if (key === 'ratings') {
          value.forEach((updateValue, updateKey) => {
            reviewDraft.get(key).set(updateKey, updateValue);
          });
        } else {
          reviewDraft.set(key, value);
        }
      });
    });
  }
}