'use es6';

import { combineReducers } from 'redux';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
import { ProfileListing } from 'marketplace-ui-solutions-core/records/ProfileListing';
import * as ActionTypes from '../actions/ActionTypes';
const details = (state = new ProfileListing(), action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_LISTING_RECEIVED:
      return new ProfileListing(action.payload);
    case ActionTypes.PROFILE_REVIEW_SUMMARY_RECEIVED:
      {
        return state.updateProfileListing({
          reviewSummary: action.payload
        });
      }
    case ActionTypes.CLEAR_PROFILE_LISTING:
      {
        return new ProfileListing();
      }
    default:
      return state;
  }
};
const detailsRequestError = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_LISTING_REQUESTED:
      return null;
    case ActionTypes.PROFILE_LISTING_FAILED:
      return action.payload;
    default:
      return state;
  }
};
const detailsRequestState = (state = RequestState.UNINITIALIZED, action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_LISTING_REQUESTED:
      return RequestState.PENDING;
    case ActionTypes.PROFILE_LISTING_RECEIVED:
      return RequestState.SUCCEEDED;
    case ActionTypes.PROFILE_LISTING_FAILED:
      return RequestState.FAILED;
    default:
      return state;
  }
};
export default combineReducers({
  details,
  detailsRequestError,
  detailsRequestState
});